<!-- 往届学生信息 -- 详情 -->
<template>
  <div v-if="dataCont" style="min-height: 100%;background-color: #f7f7f7;min-width: 900rem;padding-bottom: 60rem">
    <div class="card">
      <div class="title">基础信息</div>
      <div class="flex" style="padding: 10rem 0 30rem 45rem">
        <el-image
            :fit="'contain'"
            :preview-src-list="[dataCont.basic.photo]"
            :src="dataCont.basic.photo"
            style="width: 180rem; height: 250rem;border-radius: 20rem">
          <div slot="error" class="image-slot">
            <img alt="" src="../../../../assets/img/default-header.png">
          </div>
        </el-image>
        <div style="width: calc(100% - 248rem);padding-left: 70rem">
          <div class="flex flex-align" style="font-size: 24rem;font-weight: bold;margin-bottom: 28rem">
            <p>{{ dataCont.basic.student_name }}</p>
            <img v-if="dataCont.basic.sex == 1" alt="" class="gender-icon"
                 src="../../../../assets/img/boy.png">
            <img v-else alt="" class="gender-icon" src="../../../../assets/img/girl.png">
          </div>
          <div>
            <el-row v-for="(item,index) in personData" :gutter="20">
              <el-col v-for="(c_item, c_index) in item" :span="(index>3 && c_index>0) ? 16 : 8">
                <div v-if="c_item" class="flex flex-align">
                  <p :class="c_index === 1 && 'p3'" class="p1">{{ c_item.label }}</p>
                  <p class="p2">{{ c_item.value }}</p>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="title flex flex-align flex-between">
        <div>往届分班记录</div>
        <div>
          <router-link :to="{path:'./class',query:{id}}"
                       style="float: right;line-height: 31rem;font-size: 14rem;color: #3491FA">查看更多
          </router-link>
        </div>
      </div>
      <div style="padding: 0 25rem 40rem">
        <el-table :data="dataCont.divide" style="width: 100%">
          <el-table-column
              v-for="item in divideConfig"
              :show-overflow-tooltip="true"
              :width="item.width"
              :label="item.label"
              :prop="item.prop">
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="card">
      <div class="title flex flex-align flex-between">
        <div>往届成绩记录</div>
        <div>
          <router-link :to="{path:'./grade',query:{id}}"
                       style="float: right;line-height: 31rem;font-size: 14rem;color: #3491FA">查看更多
          </router-link>
        </div>
      </div>
      <div style="padding: 0 25rem 40rem">
        <el-table :data="dataCont.exam" style="width: 100%">
          <el-table-column
              v-for="item in classConfig"
              :show-overflow-tooltip="true"
              :width="item.width"
              :label="item.label"
              :prop="item.prop">
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-button type="primary" style="margin-top: 40rem;margin-right: 30rem" @click="$router.back();">返回</el-button>

  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import tResult from '@/components/Result.vue'

export default {
	_config:{"route":{"path":"details","meta":{"title":"查看"}}},
  data() {
    return {
      id: '',
      dataCont: '',

      divideConfig: [
        {label: '序号', prop: 'plain',width: 80},
        {label: '校区', prop: 'school_name'},
        {label: '年级', prop: 'grade_name'},
        {label: '学号', prop: 'student_no'},
        {label: '分班时间', prop: 'date'},
        {label: '科目', prop: 'subject_name'},
        {label: '层级', prop: 'level'},
        {label: '班级', prop: 'class_name'},
        {label: '教师', prop: 'teacher_name'},
        {label: '班级类型', prop: 'is_class_name'},
      ],
      classConfig:[
        {label: '校区', prop: 'school_name'},
        {label: '年级', prop: 'grade_name'},
        {label: '学号', prop: 'student_no'},
        {label: '考试名称', prop: 'exam_name'},
        {label: '考试类型', prop: 'type_name'},
        {label: '总成绩', prop: 'total_score'},
        {label: '数学', prop: 'math'},
        {label: '语文', prop: 'chinese'},
        {label: '外语', prop: 'english'},
        {label: '政治', prop: 'politics'},
        {label: '历史', prop: 'history'},
        {label: '地理', prop: 'geography'},
        {label: '物理', prop: 'physics'},
        {label: '化学', prop: 'chemistry'},
        {label: '生物', prop: 'biology'},
      ]
    }
  },
  components: {
    card: Card,
    result: tResult
  },
  mounted() {
    this.id = this.$route.query.id;

    this.getData();

  },
  methods: {
    getData() {
      let id = this.id;
      this.$_axios2.get("/api/student-rereading/index/details?id=" + id).then(res => {
        // console.log(res)
        let {data} = res.data;

        //基础信息中上半部分的信息填装，为了减少代码量
        let basic = data.basic;
        this.personData = [
          [
            {label: '学号', value: basic.student_no},
            {label: '校区', value: basic.school_name},
            {label: '年级', value: basic.grade_name},
          ],
          [
            {label: '行政班级', value: basic.class_name},
            {label: '班主任', value: basic.teacher_name},
            {label: '学习方向', value: basic.learning_direction},
          ],
        ];

        this.dataCont = data
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.c3, .c6 {
  line-height: 28rem;
}

::v-deep th {
  font-weight: normal;
}

::v-deep .text-one .cell {
  white-space: nowrap;
}

::v-deep .image-slot {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

::v-deep .el-descriptions-item__content {
  color: #333;
}

.el-tabs__header span {
  width: 120rem;
  height: 130rem;
  color: #666;
  font-size: 16rem;
  padding-left: 30rem;

  i {
    padding: 0 10rem;
    border-radius: 6rem;
  }
}

.el-tabs__header .is-active i {
  background-color: #1C4EFD;
  color: #fff;
}

.el-tabs__header .is-active span {
  color: #333;
}

::v-deep .el-tabs__item {
  height: 120rem !important;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

#echarts-a,
#echarts-b,
#echarts-c {
  height: 350rem;
}

.card {
  border-radius: 20rem 20rem 0 0;
  background-color: #fff;
  margin-bottom: 12rem;

  .gender-icon {
    width: 33rem;
    height: 33rem;
    margin-left: 12rem;
  }

  .el-row {
    margin-bottom: 20rem;
  }

  .title {
    padding: 0 60rem 0 40rem;
    line-height: 60rem;
    font-size: 18rem;
    color: #333;
    font-weight: bold;
    box-shadow: 3rem 3rem 3rem 1rem rgba(232, 237, 255, 0.16);
  }

  .p1 {
    width: 120rem;
    color: #666;
  }

  .p2 {
    width: calc(100% - 120rem);
    color: #333;
  }

  .p3 {
    width: 100rem;
  }
}

.subject-box {
  padding: 35rem 0 25rem;
  display: flex;
  justify-content: center;
  width: 14.28%;
}

.history-box {
  .step-box {
    position: relative;
  }

  .cir {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 22rem;
    height: 22rem;
    background-color: #1C4EFD;
    border-radius: 50%;
    z-index: 1;
  }

  .cir-line {
    position: absolute;
    top: 22rem;
    left: 50%;
    transform: translateX(-50%);
    width: 1rem;
    height: 100%;
    background-color: #ccc;
  }

  .div1 > div {
    word-break: keep-all;
    white-space: nowrap;
    padding-right: 40rem;
    height: 66rem;
    line-height: 66rem;
    padding-left: 20rem;
    color: #333;
  }

  &:last-child {
    .cir-line {
      display: none;
    }
  }
}

::v-deep .history-dialog {
  width: fit-content !important;
  min-width: 900rem !important;
  max-width: 1600rem !important;
  max-height: 71vh !important;
  overflow-y: auto;
}

::v-deep .tooltip {
  z-index: 1 !important;
}

.padding-top {
  padding-top: 30rem !important;
}

.type-change {
  position: absolute;
  top: 6rem;
  right: 80rem;
  border-radius: 10rem;

  span {
    border: 1rem solid #ccc;
    color: #666;
    transition: .25s;
    padding: 5rem 16rem;
    cursor: pointer;
    font-size: 14rem;
    background-color: #fff;
  }

  span.active {
    border-color: #1C4EFD;
    background-color: #1C4EFD;
    color: #fff
  }

  span:first-child {
    border-radius: 10rem 0 0 10rem;
  }

  span:last-child {
    border-radius: 0 10rem 10rem 0;
  }
}

.subject-select {
  position: absolute;
  top: 2rem;
  right: 230rem;
}

.span1 {
  cursor: pointer;
  font-size: 14rem;
  color: #666;

  &:first-child {
    margin-right: 30rem;
  }
}

.span1.active {
  color: #3491FA;
  font-size: 16rem;
}

.echarts-box {
  width: 100%;
  height: 276rem;
}

#echarts-d {
  width: 100%;
  height: 100%;
}

::v-deep .cir-college,
::v-deep .cir-this,
::v-deep .cir-current {
  display: inline-block;
  width: 8rem;
  height: 8rem;
  transform: translateY(-1rem);
  background-color: #0FC19B;
  border-radius: 50%;
  margin-right: 8rem;
}

::v-deep .cir-this {
  background-color: #3491FA;
}

::v-deep .cir-current {
  background-color: #FFC75A;
}

::v-deep #echarts-a p,
::v-deep #echarts-b p,
::v-deep #echarts-c p {
  line-height: 28rem;
}

.aaa {
  width: 90rem;
  text-align: center;
  font-size: 14rem;
  color: #666;
  line-height: 28rem;
}

.decide {
  border-bottom: 1rem solid #ebeef5;
  margin: 10rem 44rem;
}

.checking-in-box {
  padding: 29rem 0 0 35rem;

  p:nth-child(1) {
    background: #E8EDFE;
    line-height: 34rem;
    color: #1C4EFD;
    padding: 0 10rem;
    display: inline-block;
    border-radius: 5rem;
    font-size: 14rem;
  }

  p:nth-child(2) {
    color: #F76560;
    text-align: center;
    font-size: 46rem;
    padding: 25rem 0 40rem;
  }
}

@media only screen and (max-width: 1500rem) {
  .p1, .p2 {
    font-size: 14rem;
  }
  .card .p1 {
    width: 90rem
  }
  .card .p3 {
    width: 80rem;
  }
  .card .el-row {
    margin-bottom: 23rem;
  }
  .media14 {
    font-size: 14rem !important;
  }
  .before1 {
    &:before {
      font-size: 14rem;
    }
  }
}
</style>
